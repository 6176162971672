import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import FaceIcon from "@mui/icons-material/Face";

import { getAuthToken, setAuthToken } from "../../../internal/authToken";
import LanguageSelect from "../../UI/Dialogs/LanguageDialog";
import { config } from "../../../config/Constants";

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

function Settings(props) {
  const navigate = useNavigate();
  const [auth, setAuth] = React.useState(null);
  const [fetchingUser, setFetchingUser] = React.useState(true);
  const [authCompleted, setAuthCompleted] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setAuth(getAuthToken());
    function fetchUser() {
      if (getAuthToken() && getAuthToken().token) {
        const rawData = {
          client: "web",
          clientVersion: "1.0",
        };
        setFetchingUser(true);
        axios({
          method: "post",
          headers: {
            Authorization: "Bearer " + getAuthToken().token,
            "Content-Type": "application/json",
          },
          data: rawData,
          url: config.url.APP_SERVER + "/api/v1/auth/getuser",
        })
          .then(function (res) {
            if (res.data && res.data.response) {
              if (
                res.data.response.status === 1 &&
                res.data.response.httpCode === 200
              ) {
                if (res.data.response.detailedResponse[0].userEmail) {
                  setUserEmail(res.data.response.detailedResponse[0].userEmail);
                }
                setFetchingUser(false);
              } else {
                setFetchingUser(false);
              }
            }
            setAuthCompleted(true);
          })
          .catch(function (res) {
            setFetchingUser(false);
            setAuthCompleted(true);
          });
      }
    }

    if (getAuthToken() && getAuthToken().token) {
      fetchUser();
    } else {
      setAuthCompleted(true);
    }
  }, []);

  function goToLogin() {
    navigate("/settings/login");
  }

  function handleLogOut() {
    setAuth("");
    setAuthToken(null);
    navigate("/settings/");
  }

  return (
    <Grid item sx={{ backgroundColor: "background.default" }}>
      <Box
        sx={{
          marginTop: "20px",
          display: "inline-block",
          width: "100%",
          [props.theme.breakpoints.up("md")]: {
            width: "60% !important",
          },
        }}>
        <Stack>
          {auth && auth.token ? (
            <Box sx={{ textAlign: "center" }}>
              {fetchingUser ? (
                <CircularProgress />
              ) : userEmail !== "" ? (
                <Box>
                  <Chip
                    icon={<FaceIcon />}
                    size='large'
                    label={userEmail}
                    sx={{
                      fontFamily: "roboto-regular !important",
                      opacity: "0.7",
                      direction: "ltr !important",
                      fontSize: "20px",
                      fontWeight: "bold",
                      "@media (max-width:600px)": {
                        fontSize: "16px",
                      },
                    }}></Chip>
                </Box>
              ) : (
                <Box>Error when fetching user</Box>
              )}
            </Box>
          ) : (
            <Box sx={{ display: authCompleted ? "block" : "none" }}>
              <Button
                variant='contained'
                fullwidth='true'
                onClick={() => goToLogin()}
                sx={{
                  minWidth: "100%",
                }}
                startIcon={<LoginIcon />}>
                <Box sx={{ marginRight: props.theme.spacing(1) }}>
                  {t("SETTINGS LOGIN BUTTON")}
                </Box>
              </Button>
            </Box>
          )}

          <>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ marginTop: "30px", display: "none" }}>
                <LanguageSelect theme={props.theme} />
              </Box>
              {auth && auth.token ? (
                <Box
                  sx={{
                    marginTop: "20px",
                    display: authCompleted ? "block" : "none",
                  }}>
                  <Button
                    variant='outlined'
                    fullWidth
                    onClick={() => handleLogOut()}
                    sx={{
                      borderColor: "#c21728 !important",
                      color: "#c21728 !important",
                      minWidth: "100%",
                    }}
                    startIcon={<LogoutIcon />}>
                    <Box sx={{ marginRight: props.theme.spacing(1) }}>
                      {t("SETTINGS LOGOUT TEXT")}
                    </Box>
                  </Button>
                </Box>
              ) : (
                <></>
              )}
              <br />
              For business inquiries: metaguard.vpn@gmail.com
            </Box>
          </>
        </Stack>
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(Settings);
