import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";

import { isTextMatchRegex } from "../../../utils/Validations";
import { getAuthToken } from "../../../internal/authToken";
import * as snackbarActions from "../../../store/actions/snackbarActions";
import { config } from "../../../config/Constants";

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

const vpnTypes = [
  {
    name: "trojan",
    tunnels: ["bluebird"],
    tunnelRequired: true,
  },
  {
    name: "hysteria2-direct",
    tunnels: [],
    tunnelRequired: false,
  },
];

function AddAccount(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [accountName, setAccountName] = React.useState("");
  const [accountDuration, setAccountDuration] = React.useState(0);
  const [isValidAccountName, setIsValidAccountName] = React.useState(false);
  const [isValidAccountVPNType, setIsValidAccountVPNType] =
    React.useState(false);
  const [isValidAccountTunnelType, setIsValidAccountTunnelType] =
    React.useState(false);
  const [isValidAccountDuration, setIsValidAccountDuration] =
    React.useState(false);

  const [selectedVPNTypeValue, setSelectedVPNTypeValue] = React.useState("");
  const [selectedTunnelTypeValue, setSelectedTunnelTypeValue] =
    React.useState("");

  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [creatingAccount, setCreatingAccount] = React.useState(false);

  useEffect(() => {}, []);

  const handleVPNTypeChange = (event) => {
    const selectedVPNType = vpnTypes.find((c) => c.name === event.target.value);
    const validAccountVPNType = isTextMatchRegex(
      selectedVPNType.name,
      "^[a-zA-Z0-9-_]+$"
    );

    setSelectedVPNTypeValue(selectedVPNType);
    setIsValidAccountVPNType(validAccountVPNType);
    setSelectedTunnelTypeValue("");

    if (isValidAccountName && validAccountVPNType && isValidAccountDuration) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const handleTunnelTypeChange = (event) => {
    const tunnel = event.target.value;
    const validAccountTunnelType = isTextMatchRegex(tunnel, "^[a-zA-Z0-9_]+$");

    setSelectedTunnelTypeValue(tunnel);
    setIsValidAccountTunnelType(validAccountTunnelType);
  };

  function handleAccountNameChange(event) {
    let accountNameHolder = event.target.value;
    const validAccountName = isTextMatchRegex(
      accountNameHolder,
      "^[a-zA-Z0-9_]+$"
    );

    setAccountName(accountNameHolder);
    setIsValidAccountName(validAccountName);
    if (
      validAccountName &&
      isValidAccountVPNType &&
      isValidAccountDuration &&
      (!selectedVPNTypeValue.tunnelRequired ||
        (selectedVPNTypeValue.tunnelRequired && isValidAccountTunnelType))
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }

  function handleAccountDurationChange(event) {
    let accountDurationHolder = parseInt(event.target.value, 10);
    const validAccountDuration =
      accountDurationHolder > 0 && accountDurationHolder < 365;

    setAccountDuration(accountDurationHolder);
    setIsValidAccountDuration(validAccountDuration);
    if (
      isValidAccountName &&
      isValidAccountVPNType &&
      validAccountDuration &&
      (!selectedVPNTypeValue.tunnelRequired ||
        (selectedVPNTypeValue.tunnelRequired && isValidAccountTunnelType))
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }

  function handleCreateClick(event) {
    if (event) {
      event.preventDefault();
    }

    if (
      isValidAccountName &&
      isValidAccountVPNType &&
      isValidAccountDuration &&
      (!selectedVPNTypeValue.tunnelRequired ||
        (selectedVPNTypeValue.tunnelRequired && selectedTunnelTypeValue))
    ) {
      setCreatingAccount(true);
      const rawData = {
        accountName: accountName,
        accountVPNType: selectedVPNTypeValue.name,
        accountTunnelEnabled:
          selectedVPNTypeValue.tunnelRequired && selectedTunnelTypeValue !== ""
            ? true
            : false,
        accountTunnelType: selectedTunnelTypeValue,
        accountDuration: parseInt(accountDuration, 10),
        lng: "en",
        client: "web",
        clientVersion: "1.0",
      };
      console.log(rawData);
      axios({
        method: "post",
        url: config.url.APP_SERVER + "/api/v1/accounts/new",
        data: rawData,
        headers: {
          Authorization: "Bearer " + getAuthToken().token,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (
            res.data &&
            res.data.response.status === 1 &&
            res.data.response.httpCode === 200
          ) {
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: res.data.response.message,
                severity: "success",
                autoHide: true,
              })
            );
            setTimeout(() => {
              navigate("/dashboard/vpn/accounts/");
            }, 2000);
          } else {
            setCreatingAccount(false);
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: res.data.response.error.errorContent[0].message,
                severity: "error",
                autoHide: res.data.response.error.errorAutoHide,
              })
            );
          }
        })
        .catch((err) => {
          setCreatingAccount(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: err.response.data.response.error.errorContent[0].message,
              severity: "error",
              autoHide: true,
            })
          );
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Incomplete information",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  return (
    <Grid item sx={{ backgroundColor: "background.default" }}>
      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "70px",
          display: "inline-block",
          width: "100%",
          [props.theme.breakpoints.up("md")]: {
            width: "60% !important",
          },
        }}>
        <Stack>
          <Stack sx={{ textAlign: "center !important" }}>
            <Box>
              <Typography
                component='div'
                gutterBottom
                sx={{
                  color: "primary.title",
                  fontSize: "24px",
                  fontWeight: "bold",
                  "@media (max-width:600px)": {
                    fontSize: "18px",
                  },
                }}>
                {t("DASHBOARD ADD NEW ACCOUNT")}
              </Typography>
            </Box>
          </Stack>
          <form autoComplete='off'>
            <TextField
              id='outlined-account-name'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "20px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("DASHBOARD ADD NEW ACCOUNT VPN ACCOUNT NAME")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleAccountNameChange}
            />

            <FormControl fullWidth>
              {!selectedVPNTypeValue && (
                <InputLabel
                  shrink={false}
                  sx={{
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    opacity: "0.6",
                    transform: "translate(-50%, -50%)",
                  }}>
                  {t("DASHBOARD ADD NEW ACCOUNT VPN TYPE")}
                </InputLabel>
              )}

              <Select
                value={selectedVPNTypeValue ? selectedVPNTypeValue.name : ""}
                onChange={handleVPNTypeChange}
                id='outlined-server-type'
                fullWidth={true}
                shrink='false'
                variant='outlined'
                inputProps={{
                  style: {
                    textAlign: "center",
                    direction: "ltr",
                    flip: "false",
                  },
                }}>
                <MenuItem value='' disabled>
                  <em>{t("DASHBOARD ADD NEW ACCOUNT VPN TYPE")}</em>
                </MenuItem>
                {vpnTypes &&
                  vpnTypes.map((c) => (
                    <MenuItem key={c.name} value={c.name}>
                      {c.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginTop: "5px" }}>
              {!selectedTunnelTypeValue && (
                <InputLabel
                  shrink={false}
                  sx={{
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    opacity: "0.6",
                    transform: "translate(-50%, -50%)",
                  }}>
                  {t("DASHBOARD ADD NEW ACCOUNT TUNNEL TYPE")}
                </InputLabel>
              )}
              <Select
                value={selectedTunnelTypeValue}
                onChange={handleTunnelTypeChange}
                disabled={!selectedVPNTypeValue.tunnelRequired}
                id='outlined-tunnel-type'
                fullWidth={true}
                shrink='false'
                variant='outlined'
                inputProps={{
                  style: {
                    textAlign: "center",
                    direction: "ltr",
                    flip: "false",
                  },
                }}>
                <MenuItem value='' disabled>
                  <em>{t("DASHBOARD ADD NEW ACCOUNT TUNNEL TYPE")}</em>
                </MenuItem>
                {selectedVPNTypeValue &&
                  selectedVPNTypeValue.tunnels &&
                  selectedVPNTypeValue.tunnels.map((tunnel) => (
                    <MenuItem key={tunnel} value={tunnel}>
                      {tunnel}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <TextField
              id='outlined-account-duration'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 35,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "5px" }}
              type='text'
              required
              margin='normal'
              placeholder={t("DASHBOARD ADD NEW ACCOUNT VPN ACCOUNT DURATION")}
              fullWidth={true}
              variant='outlined'
              autoComplete='off'
              onChange={handleAccountDurationChange}
            />

            <Button
              variant='contained'
              fullwidth='true'
              disabled={btnDisabled || creatingAccount ? true : false}
              disableElevation={true}
              onClick={() => handleCreateClick()}
              sx={{ minWidth: "100%" }}
              startIcon={<AddIcon />}>
              <Box sx={{ marginRight: props.theme.spacing(1) }}>
                {creatingAccount
                  ? t("DASHBOARD ADD NEW ACCOUNT CREATING")
                  : t("DASHBOARD ADD NEW ACCOUNT CREATE")}
              </Box>
            </Button>
          </form>
        </Stack>
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(AddAccount);
